import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';

import { AUTH_PREFIX_PATH, UNAUTHENTICATED_ENTRY, REDIRECT_URL_KEY } from 'configs/AppConfig';
import UserService from 'services/UserService';
import { updateUserInfo } from 'store/slices/authSlice';

const ProtectedRoute = () => {
  const { token, userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      UserService.getPersonalInfo()
        .then(async (res) => {
          const info = await JSON.parse(res?.message);
          dispatch(updateUserInfo({ info: info.info }));
        })
        .catch((e) => {
          message.error(e.message);
        });
    }
  }, [location]);
  if (userInfo?.wizard) {
    return <Navigate to="/wizard" replace />;
  }

  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }
  return <Outlet />;
};

export default ProtectedRoute;
