import React, { Component } from 'react';
import { Result, Button } from 'antd';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by error boundary:', error, errorInfo);
    //
  }

  render() {
    const buttonStyle = {
      backgroundColor: '#A3DC2F',
      borderColor: '#A3DC2F',
      color: '#ffffff'
    };

    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    };

    if (this.state.hasError) {
      return (
        <div style={containerStyle}>
          <Result
            status="error"
            title={<span>Oops! Something went wrong.</span>}
            subTitle={
              <span>
                An error occurred while processing your request. Please check your network
                connection and try again.
              </span>
            }
            extra={
              <Button type="primary" style={buttonStyle} onClick={() => window.location.reload()}>
                Retry
              </Button>
            }
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
