import fetch from 'auth/FetchInterceptor';

const UserService = {};

//-----------Profile Management----------//

UserService.getPersonalInfo = async function (params) {
  return fetch({
    url: '/user/info',
    method: 'get',
    params
  });
};

UserService.updatePersonalInfo = async function (data) {
  return fetch({
    url: '/user/edit',
    method: 'post',
    data
  });
};

UserService.getNotifications = async function () {
  return fetch({
    url: '/key/status',
    method: 'get'
  });
};

UserService.setNotificationAsMarked = async function (data) {
  const headers = {
    'Content-Type': 'application/json'
  };
  return fetch({
    url: '/key/messages/read',
    method: 'POST',
    headers: headers,
    data
  });
};

export default UserService;
