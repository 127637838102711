import fetch from 'auth/FetchInterceptor';

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: '/auth/signin',
    method: 'post',
    data: data
  });
};

AuthService.register = function (data) {
  return fetch({
    url: '/auth/signup',
    method: 'post',
    data: data
  });
};
AuthService.getPrice = function (currency) {
  return fetch({
    url: `/auth/getPrice?currency=${currency}`,
    method: 'get'
  });
};

AuthService.logout = function () {
  return fetch({
    url: '/auth/logout',
    method: 'post'
  });
};

export default AuthService;
