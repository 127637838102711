// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-icon-custom {
  font-size: 18px !important;
}

.custom-menu .ant-menu-item,
.custom-menu .ant-menu-item-group-title {
  border-radius: 0;
}

body[data-theme=dark] .custom-link a {
  color: #b4bed2;
}

.custom-link a {
  text-decoration: none;
  color: #455560;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/comon.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AACA;;EAEE,gBAAA;AAEF;;AACE;EACE,cAAA;AAEJ;;AACA;EACE,qBAAA;EACA,cAAA;AAEF","sourcesContent":[".sidebar-icon-custom {\n  font-size: 18px !important;\n}\n.custom-menu .ant-menu-item,\n.custom-menu .ant-menu-item-group-title {\n  border-radius: 0;\n}\nbody[data-theme='dark'] {\n  .custom-link a {\n    color: #b4bed2;\n  }\n}\n.custom-link a {\n  text-decoration: none;\n  color: #455560;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
