import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import wallet from './slices/walletSlice';
import notifications from './slices/notificationSlice';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    wallet,
    notifications,
    ...asyncReducers
  });
  return combinedReducer(state, action);
};

export default rootReducer;
