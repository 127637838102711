import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import UserService from 'services/UserService';

export const initialState = {
  loading: false,
  message: '',
  notificationData: {}
};

export const getNotifications = createAsyncThunk(
  'notification',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserService.getNotifications();

      if (response.success === false) {
        return rejectWithValue(response.message || 'Error');
      }

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const notifcationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateNotifications: (state, action) => {
      state.notificationData = action.payload.info;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        if (action.payload?.success === false) {
          state.messageType = 'warning';
          state.message = 'Try Agin';
        }
        state.loading = false;
        state.notificationData = JSON.parse(action.payload.message);
      })
      .addCase(getNotifications.rejected, (state, action) => {
        if (action.payload?.success === false) {
          state.messageType = 'warning';
          state.message = 'Try Agin';
        }
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  }
});

export const { updateNotifications } = notifcationSlice.actions;

export default notifcationSlice.reducer;
