import {
  SIDE_NAV_LIGHT,
  /* NAV_TYPE_TOP ,*/ NAV_TYPE_SIDE,
  DIR_LTR
} from 'constants/ThemeConstant';

const getCurrentTheme = () => {
  const now = new Date(),
    start = new Date(),
    end = new Date();

  start.setHours(22, 0, 0);
  end.setHours(8, 0, 0);
  if (localStorage.getItem('theme')) {
    return localStorage.getItem('theme');
  }
  if (now >= start || now <= end) {
    return 'dark';
  } else {
    return 'light';
  }
};

export const APP_NAME = 'Flitter-Trade';
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect';
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}`;
export const UNAUTHENTICATED_ENTRY = '/login';

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#002E33',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: getCurrentTheme(),
  direction: DIR_LTR,
  blankLayout: false,
  hideSidebar: false
};
