import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';

const WizardRoute = () => {
  const { token, userInfo } = useSelector((state) => state.auth);
  if (token && userInfo?.wizard === false) {
    return <Navigate to={AUTHENTICATED_ENTRY} />;
  }
  return token && userInfo?.wizard ? <Outlet /> : <Navigate to="/wizare" />;
};

export default WizardRoute;
