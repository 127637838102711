import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';

import AuthService from 'services/AuthService';

export const initialState = {
  loading: false,
  message: '',
  messageType: 'error',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN) || null,
  userInfo: {}
};

export const signIn = createAsyncThunk('auth/login', async (data, { rejectWithValue }) => {
  const { usernameOrEmail, password } = data;
  try {
    const response = await AuthService.login({ usernameOrEmail, password });

    if (response.success === false) {
      return rejectWithValue(response.message || 'Error');
    }

    localStorage.setItem(AUTH_TOKEN, response?.accessToken);

    return response;
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error');
  }
});

export const signUp = createAsyncThunk('auth/signup', async (data, { rejectWithValue }) => {
  const { email, password, name, username } = data;
  try {
    const response = await AuthService.register({
      email,
      password,
      name,
      username
    });
    return response;
  } catch (err) {
    return rejectWithValue(err?.error || 'Error ');
  }
});

export const signOut = createAsyncThunk('auth/signOut', async () => {
  localStorage.removeItem(AUTH_TOKEN);
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = '/';
      state.token = action.payload?.accessToken;
      state.userInfo = action.payload?.info;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = '';
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.userInfo = null;
      state.redirect = '/';
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload?.accessToken;
      state.userInfo = action.payload?.info;
    },
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload.info;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        if (action.payload?.success === false) {
          state.messageType = 'warning';
          state.message = 'Try Agin';
        }
        state.loading = false;
        state.redirect = '/';
        state.token = action.payload?.accessToken;
        state.userInfo = action.payload?.info;
      })
      .addCase(signIn.rejected, (state, action) => {
        if (action.payload?.success === false) {
          state.messageType = 'warning';
          state.message = 'Try Agin';
        }
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = '/';
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.userInfo = null;
        state.redirect = '/';
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        if (action.payload?.success === true) {
          state.messageType = 'success';
        } else {
          state.messageType = 'warning';
        }
        state.message = action.payload?.message || 'Fulfilled';
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload?.message || 'Error';
        state.showMessage = true;
        state.loading = false;
      });
  }
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  updateUserInfo
} = authSlice.actions;

export default authSlice.reducer;
