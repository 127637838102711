import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/auth-views/authentication/login'))
  },
  {
    key: 'register',
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() => import('views/auth-views/authentication/register'))
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import('views/auth-views/authentication/forgot-password'))
  },
  {
    key: 'privacy',
    path: `${AUTH_PREFIX_PATH}/privacy`,
    component: React.lazy(() => import('views/app-views/privacy'))
  },
  {
    key: 'terms',
    path: `${AUTH_PREFIX_PATH}/terms`,
    component: React.lazy(() => import('views/app-views/terms'))
  }
];

export const protectedRoutes = [
  {
    key: 'default',
    path: `${APP_PREFIX_PATH}`,
    component: React.lazy(() => import('views/app-views/wallet'))
  },
  {
    key: 'privacy',
    path: `${APP_PREFIX_PATH}/privacy`,
    component: React.lazy(() => import('views/app-views/privacy'))
  },
  {
    key: 'terms',
    path: `${APP_PREFIX_PATH}/terms`,
    component: React.lazy(() => import('views/app-views/terms'))
  },
  {
    key: 'setting',
    path: `${APP_PREFIX_PATH}/setting/*`,
    component: React.lazy(() => import('views/app-views/setting'))
  },
  {
    key: 'trading',
    path: `${APP_PREFIX_PATH}/trading`,
    component: React.lazy(() => import('views/app-views/trading'))
  },
  {
    key: 'history',
    path: `${APP_PREFIX_PATH}/history`,
    component: React.lazy(() => import('views/app-views/history'))
  },
  {
    key: 'docs.documentation',
    path: `${APP_PREFIX_PATH}/docs/*`,
    component: React.lazy(() => import('views/app-views/docs'))
  }
];

export const wizardRoutes = [
  {
    key: 'wizard',
    path: '/wizard',
    component: React.lazy(() => import('views/app-views/wizard'))
  }
];
