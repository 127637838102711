import { API_BASE_URL } from 'configs/AppConfig';
import fetch from 'auth/FetchInterceptor';

// import UserToken from "./utils";
const WizardService = {};

/**
 * Send hiss api key
 */

WizardService.addHissKey = async function (payload) {
  const config = {
    method: 'post',
    url: `${API_BASE_URL}/key/hiss`,
    data: payload
  };

  return fetch(config);
};

/**
 * Send Bin key
 */
WizardService.getKeyBin = async function (payload) {
  const config = {
    method: 'post',
    url: `${API_BASE_URL}/key/bin`,
    data: payload
  };
  return fetch(config);
};

/**
 * Get wallet crypto
 */

WizardService.getWallet = async function () {
  const config = {
    method: 'get',
    url: `${API_BASE_URL}/wallet/get`
  };

  return fetch(config);
};

WizardService.getWallet = function (data) {
  return fetch({
    url: `${API_BASE_URL}/wallet/get`,
    method: 'get',
    data: data
  });
};

/**
 * Edit wallet param
 */
WizardService.editWallet = async function (payload) {
  const config = {
    method: 'post',
    url: `${API_BASE_URL}/wallet/edit`,
    data: payload
  };
  return fetch(config);
};
WizardService.buyNow = async function (payload) {
  const config = {
    method: 'get',
    url: `${API_BASE_URL}/wallet/buy/${payload.currency}`,
    data: payload
  };
  return fetch(config);
};
WizardService.getIndices = async function (payload) {
  const config = {
    method: 'get',
    url: `${API_BASE_URL}/wallet/indices`,
    data: payload
  };
  return fetch(config);
};

/**
 * Change status wallet
 */
WizardService.changeStatusWalletTrade = async function (payload) {
  return fetch({
    method: 'post',
    url: `${API_BASE_URL}/wallet/status`,
    data: payload
  });
};
WizardService.setupLaterWizard = async function () {
  return fetch({
    method: 'get',
    url: `${API_BASE_URL}/key/wizard/off`
  });
};

WizardService.addTelegramLogin = async function (payload) {
  const config = {
    method: 'post',
    url: `${API_BASE_URL}/user/tg/add`,
    data: payload
  };

  return fetch(config);
};
WizardService.deleteTelegramLogin = async function (payload) {
  const config = {
    method: 'post',
    url: `${API_BASE_URL}/user/tg/delete`,
    data: payload
  };

  return fetch(config);
};
export default WizardService;
