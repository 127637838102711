import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import WizardService from 'services/WizardService';

// Async thunk for loading wallet data
export const loadWalletData = createAsyncThunk('wallet/loadData', async () => {
  const response = await WizardService.getWallet();
  return JSON.parse(response?.message);
});

export const getIndices = createAsyncThunk(
  'notification/indices',
  async (_, { rejectWithValue }) => {
    try {
      const response = await WizardService.getIndices();
      return JSON.parse(response?.message);
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const buyNow = createAsyncThunk('wallet/buyNow', async (data, { rejectWithValue }) => {
  try {
    const response = await WizardService.buyNow(data);
    return response;
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error');
  }
});

// Slice
const walletSlice = createSlice({
  name: 'wallet',
  initialState: { data: [], loading: false, error: null, shouldUpdateWallet: false, indices: [] },

  reducers: {
    updateWallet: (state, action) => {
      state = action.payload;
    },
    updateWalletState: (state) => {
      state.shouldUpdateWallet = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadWalletData.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadWalletData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.shouldUpdateWallet = false;
      })
      .addCase(loadWalletData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getIndices.fulfilled, (state, action) => {
        state.loading = false;
        state.indices = action.payload;
      })
      .addCase(getIndices.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || 'Error';
      });
  }
});

export const { updateWallet, updateWalletState } = walletSlice.actions;
export default walletSlice.reducer;
